import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

import InteriorHero from "../components/interior-components/InteriorHero";
import ReactMarkdown from 'react-markdown'
import rehypeRaw from "rehype-raw";

// eslint-disable-next-line

export const ReviewsPageTemplate = ({
  rev_image,
  title,
  rev_heroHeadline,
  rev_reviews,
  contentComponent,
  content
}) => {
  const PageContent = contentComponent || Content;

  return (
    <div className="main-page-content">
      <InteriorHero image={rev_image} title={title} heroHeadline={rev_heroHeadline} showTitle={true} thirdLevel={false} />
      <section className="section pt-0 pb-0 not-porfolio-gallery-grey">
        <div className="container">
          <div className="section pt-0 pb-0">
            <div className="columns is-gapless">
              <div className="column">
                <div className="py-6">
                  <PageContent className="content" content={content} />
                  <br/>
                  <div className="testimonialsection__list">
                  {rev_reviews.map((review, index) => (    
                    <div class="testimonialsection__quote is-flex is-align-items-center testimonialsection__single">
                      <div class="testimonialsection__quote_inner">
                        <div class="testimonialsection__quote_copy">
                          <ReactMarkdown key={"review_"+index} children={review.review} rehypePlugins={[rehypeRaw]} />
                        </div>
                      </div>
                    </div>
                  ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

ReviewsPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  rev_heroHeadline: PropTypes.string,
  rev_image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  rev_reviews: PropTypes.object,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const ReviewsPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ReviewsPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        rev_heroHeadline={post.frontmatter.rev_heroHeadline}
        rev_image={post.frontmatter.rev_image}
        rev_reviews={post.frontmatter.rev_reviews}
        content={post.html}
      />
    </Layout>
  );
};

ReviewsPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ReviewsPage;

export const ReviewsPageQuery = graphql`
  query ReviewsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        rev_heroHeadline
        rev_image{
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        rev_reviews{
          review
        }
      }
    }
  }
`;
